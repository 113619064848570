import React from "react";
import styles from "./Header.module.css";
import { mainLogo, search, store } from "../../Images";

export const Header = () => {
  const dataArr = [
    "Store",
    "Mac",
    "iPad",
    "iPhone",
    "watch",
    "Vision",
    "AirPods",
    "TV & Home",
    "entertainment",
    "Accessories",
    "Support",
  ];

  return (
    <div className={styles.container}>
      <div style={{ alignSelf: "center", marginTop: 5 }}>
        <img src={mainLogo} style={{ width: 25, height: 25 }} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "50%",
          justifyContent: "space-evenly",
          alignSelf: "center",
        }}
      >
        {dataArr.map((item, index) => {
          return (
            <div
              onClick={() => {
                console.log(item);
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignSelf: "center",
                color: "white",
                cursor: "pointer",
              }}
            >
              {item}
            </div>
          );
        })}

        <img
          onClick={() => {
            console.log("Search");
          }}
          src={search}
          style={{
            cursor: "pointer",
            width: 14.08,
            height: 14.08,
            alignSelf: "center",
            marginTop: 5,
          }}
        />
        <img
          onClick={() => {
            console.log("bag");
          }}
          src={store}
          style={{
            width: 14.08,
            height: 14.08,
            alignSelf: "center",
            marginTop: 5,
            cursor: "pointer",
          }}
        />
      </div>
    </div>
  );
};
