import "./App.css";
import { Card, Header } from "./Components";
import {
  figure1,
  figure2,
  figure3,
  figure5,
  figure6,
  figure7,
  figure8
} from "./Images";

const cardData = [
  {
    route: `${figure1}`,
    header: "Horus",
    description: "Titanium. So strong. So light. So Pro.",
    primaryLink: "Learn more >",
    secondaryLink: "Buy >",
    primaryColor: "white",
  },
  {
    route: figure2,
    header: "Saamarthak",
    description: "New camera. New design. Newphoria.",
    primaryLink: "Learn more >",
    secondaryLink: "Buy >",
  },
  {
    route: figure3,
    header: "BumbleeBee",
    description: "New camera. New design. Newphoria.",
    primaryLink: "Learn more >",
    secondaryLink: "Buy >",
  },
  {
    route: figure2,
    header: "Sandbox",
    description: "New camera. New design. Newphoria.",
    primaryLink: "Learn more >",
    secondaryLink: "Buy >",
  },
];
const gridData = [
  {
    route: figure5,
    header: "Containers",
    description: "Mind-blowing. Head-turning.",
    primaryLink: "Learn more >",
    secondaryLink: "Buy >",

  },
  {
    route: figure6,
    header: "Cybersecurity",
    description: "Lovable. Drawable. Magical.",
    primaryLink: "Learn more >",
    secondaryLink: "Buy >",
  },
  {
    route: figure7,
    header: "Development",
    description: "Adaptive Audio. Now playing.",
    primaryLink: "Learn more >",
    secondaryLink: "Buy >",
    primaryColor: "white",
  },
  {
    route: figure8,
    header: "DevOps",
    description: "Impressively big. Impossibly thin.",
    primaryLink: "Learn more >",
    secondaryLink: "Buy >",
  },
];

function App() {
  return (
    <div className="App">
      <Header />
      <Card cardData={cardData} />
      <Card
        cardData={gridData}
        cardType={"grid"}
        cardTextContainerStyle={{ marginTop: 50 }}
      />
    </div>
  );
}

export default App;
