import React from "react";

export const Card = ({
  cardData,
  cardStyle,
  cardTextContainerStyle,
  cardType,
}) => {
  return (
    <>
     <div style={cardType === 'grid' ? {display:'grid', gridTemplateColumns:'auto auto'}: {}}>
          {cardData.map((item) => (
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                ...cardStyle,
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  color: "white",
                  fontSize: 30,
                  flexDirection: "column",
                  marginTop: "150px",
                  ...cardTextContainerStyle,
                }}
              >
                <div
                  style={{
                    color: item?.primaryColor || "black",
                    fontSize: "50.422px",
                    fontFamily: "Arial",
                    fontWeight: "700",
                  }}
                >
                  {item.header}
                </div>
                <div
                  style={{
                    color: item?.primaryColor || "black",
                    fontSize: "28px",
                    fontFamily: "Arial",
                    fontWeight: "400",
                    lineHeight: 2,
                  }}
                >
                  {item.description}
                </div>
                <div
                  style={{
                    color: "#06C",
                    fontSize: "21px",
                    fontFamily: "Arial",
                    fontWeight: "400",
                    lineHeight: 2,
                  }}
                >
                  <span style={{ marginRight: "40px" }}>
                    {item.primaryLink}
                  </span>
                  <span>{item.secondaryLink}</span>
                </div>
              </div>
              <img src={item.route} style={{ width: "100%" }} />
            </div>
          ))}
        </div>
      {/* {cardType === "grid" && <div style={{display:'grid'}}></div>} */}
    </>
  );
};
