import mainLogo from "./mainLogo.svg";
import search from "./search.svg";
import store from "./store.svg";
import figure1 from "./Figure1.png";
import figure2 from "./Figure2.png";
import figure3 from "./Figure3.png";
import figure5 from "./Figure5.png";
import figure6 from "./Figure6.png";
import figure7 from "./Figure7.png";
import figure8 from "./Figure8.png";

export {
  mainLogo,
  search,
  store,
  figure1,
  figure2,
  figure3,
  figure5,
  figure6,
  figure7,
  figure8,
};
